import React from 'react';
import { Box, Center, Heading } from '@chakra-ui/layout';
import { QABox } from '../components';
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css';

export default function Faqs() {
  const data = [
    {
      q: 'Why',
      a: 'answer',
    },
    {
      q: 'Why',
      a: 'answer',
    },
    {
      q: 'Why',
      a: 'answer',
    },
    {
      q: 'Why',
      a: 'answer',
    },
    {
      q: 'Why',
      a: 'answer',
    },
  ];
  return (
    <Box bg="white" p={'2rem'} pb={'10rem'}>
      <Box bg="white" pb={'2rem'}>
        <ScrollAnimation animateIn="animate__slideInUp" animateOnce={true}>
          <Heading
            textStyle="2xl"
            h="100px"
            color="green.solid"
            textAlign={'center'}
          >
            Frequently Asked Questions
          </Heading>
        </ScrollAnimation>
      </Box>
      <Box mx={'4rem'}>
        {data?.map((item) => {
          return <QABox question={item.q} answer={item.a} />;
        })}
      </Box>
    </Box>
  );
}
